'use strict'

module.exports = class BufferedSet {
    constructor() {
        this._inProgress = new Set()
        this._finished = new Set()
    }

    start(item) {
        this._inProgress.add(item)
        return this
    }

    finish(item) {
        this._inProgress.delete(item)
        this._finished.add(item)
        return this
    }

    flush() {
        const values = [...this._finished]
        this._finished.clear()
        return values
    }

    waitingCount() {
        return this._inProgress.size
    }

    finishedCount() {
        return this._finished.size
    }
}
