'use strict'

module.exports = ({storage, behaviorsAspect}) => {
    const fetchers = {
        'Component.compActions': (state, props) => storage.get(props.id) || {},
        'Component.compDynamicBehaviorsClearAfterFlush': () => {},
        'Behaviors.handleAction': (state, {id: componentId}) => (action, event) => behaviorsAspect.handleAction(componentId, action, event)
    }

    return ({id}) => fetchers[id]
}
