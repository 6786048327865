'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const constants = require('../utils/constants')
const {generateStyleNodeProps} = require('../utils/styleNodeUtils')
const {createCompProps} = require('../utils/propsFetcher')
const {loadFonts} = require('../utils/fontsLoader')

const {COMPONENT_TYPE, STYLE_ID} = constants.pointers.components.PROPERTY_TYPES

const StyleNode = (props, {componentsModelAspect, getCompClass, rootId, getAspect, fetchSantaType}) => {
    try {
        const {id} = props
        const {displayedDAL, pointers} = componentsModelAspect
        const componentPointer = pointers.components.getComponent(id)
        const styleId = displayedDAL.get(pointers.components.getProperty(componentPointer, STYLE_ID))

        const componentType = displayedDAL.get(pointers.components.getProperty(componentPointer, COMPONENT_TYPE))
        const componentClass = getCompClass(componentType)

        if (!componentClass.getCompCss) {
            return null
        }

        const cssProps = createCompProps({fetchSantaType}, componentClass.getCompCss.cssTypes, props)
        const cssData = componentClass.getCompCss(styleId, cssProps)

        const fontsLoaderAspect = getAspect('fontsLoaderAspect')
        if (fontsLoaderAspect && componentClass.getCompFonts) {
            const fontsProps = createCompProps({fetchSantaType}, componentClass.getCompFonts.fontsTypes, props)
            const fontsData = componentClass.getCompFonts(_.keys(cssData), fontsProps)
            loadFonts(fontsLoaderAspect, fontsData)
        }

        return (
            <React.Fragment>
                {_.map(cssData, (css, innerStyleId) => <style {...generateStyleNodeProps(innerStyleId, css, rootId)}/>)}
            </React.Fragment>
        )
    } catch (e) {
        return <style id={props.id} data-dead-style/>
    }
}

StyleNode.displayName = 'ComponentStyleNode'

StyleNode.propTypes = {
    id: PropTypes.string.isRequired
}

StyleNode.contextTypes = {
    getCompClass: PropTypes.func.isRequired,
    componentsModelAspect: PropTypes.shape({
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired,
    rootId: PropTypes.string,
    getAspect: PropTypes.func,
    fetchSantaType: PropTypes.func
}


module.exports = StyleNode
