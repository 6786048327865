'use strict'
const _ = require('lodash')

const DEFAULTS = {
    Component: {
        compBehaviors: [],
        shouldHideAnimatable: false,
        rootId: null,
        currentUrlPageId: null,
        scale: 1
    },
    Utils: {
        logger: {error: console.error},
        logging: {
            performance: {
                now: _.now
            }
        }
    },
    siteWidth: 980,
    Layout: {
        registerLayoutFunc: _.noop,
        registerReLayoutPending: _.noop,
        reLayoutIfPending: _.noop
    },
    isExperimentOpen: _.constant(false),
    Animations: {
        animationProperties: {}
    },
    isMobileView: false,
    Device: {
        isTouchDevice: false,
        isMobileDevice: false
    },
    isDebugMode: false,
    isQAMode: false,
    hideComponentsListForQa: '',
    RenderFlags: {
        componentPreviewState: ''
    },
    Modes: {
        activateModeById: _.noop,
        deactivateModeById: _.noop,
        switchModesByIds: _.noop
    },
    ServiceTopology: {
        scriptsLocationMap: {}
    },
    setCustomClickOccurred: _.noop,
    SiteButton: {
        link: null,
        impliedLink: null
    },
    Browser: {
        browser: {
            ie: false
        }
    }
}

module.exports = {
    getFetcher(santaTypeDefinition) {
        if (!_.has(DEFAULTS, santaTypeDefinition.id)) {
            console.warn(`${santaTypeDefinition.id} is not defined`)
            return () => null
        }

        const defaultValue = _.get(DEFAULTS, santaTypeDefinition.id)
        return () => defaultValue
    }
}
