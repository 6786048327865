'use strict'

const _ = require('lodash')
const mobx = require('mobx')
const displayedDal = require('./displayedDal')
const runtimeDal = require('./runtimeDal')
const pointers = require('./pointers')
const componentPropsFetchers = require('./componentPropsFetchers')
const modelBuilder = require('./carmi/modelBuilder')
const functionLibrary = require('./carmi/functionLibrary')
const constants = require('../../utils/constants')
const runtimeUtil = require('../../utils/runtime')

const {DATA_MAPS} = constants.pointers.data
const privatesMap = new WeakMap()

class ComponentsModelAspect {
    constructor(componentsModel, eventsManager, schemas = {}, isStaticModel = false) {
        const model = {
            structure: isStaticModel ? new Map() : mobx.observable.shallowMap(),
            runtimeComponentActions: isStaticModel ? new Map() : mobx.observable.map(),
            data: _(DATA_MAPS).keyBy().mapValues(() => isStaticModel ? new Map() : mobx.observable.shallowMap()).value(),
            meshLayout: isStaticModel ? new Map() : mobx.observable.map(),
            fixedChildrenIDs: isStaticModel ? new Map() : mobx.observable.map()
        }
        this.model = model

        const runtime = runtimeUtil.createEmptyRuntime()
        const activeModes = {}
        const contextsMap = {}
        const functionLib = functionLibrary.create(eventsManager, schemas, model, isStaticModel)
        const carmiModel = modelBuilder(_.defaults({contextsMap, activeModes, runtime}, componentsModel), functionLib)
        this.carmiModel = carmiModel

        const getStructure = id => model.structure.get(id)

        this.pointers = pointers
        this.displayedDAL = displayedDal.create(carmiModel)
        this.runtimeDAL = runtimeDal.create(carmiModel, this.displayedDAL, schemas, eventsManager)
        this.getDataByQuery = (dataQuery, type) => model.data[DATA_MAPS[type]].get(dataQuery.replace('#', ''))
        this.getStructureProperty = (id, property) => {
            const path = pointers.getPath(pointers.components.getProperty(pointers.components.getComponent(id), property))
            return _.get(getStructure(id), _.last(path))
        }

        privatesMap.set(this, {
            isStaticModel,
            propsFetcher: componentPropsFetchers.create(_.defaults({getStructure}, this))
        })
    }

    getFetcher(santaTypeDefinition) {
        const {propsFetcher} = privatesMap.get(this)
        return _.get(propsFetcher, santaTypeDefinition.id)
    }

    isStaticModel() {
        return privatesMap.get(this).isStaticModel
    }

    updateActiveModes(modeId, value) {
        this.carmiModel.updateActiveModes(modeId, value)
    }
}

module.exports = ComponentsModelAspect
