'use strict'

const _ = require('lodash')
const BufferedSet = require('./BufferedSet')

class Layout {
    constructor() {
        this._bufferedSet = new BufferedSet()
        this._registeredComponents = {}
    }

    shouldPerform() {
        return this._bufferedSet.waitingCount() === 0 &&
            this._bufferedSet.finishedCount() > 0 &&
            Object.keys(this._registeredComponents).length > 0
    }

    perform({componentsModelAspect, getMeasurerForType}) {
        _(this._bufferedSet.flush())
            .map(id => {
                const type = componentsModelAspect.getStructureProperty(id, 'componentType')
                const measurer = getMeasurerForType(type)
                const componentInstance = this._registeredComponents[id]
                if (!measurer || !componentInstance) {
                    return null
                }
                return {measurer, componentInstance}
            }).compact().flatMap(({measurer, componentInstance}) => {
                if (typeof componentInstance.getRootRef !== 'function') {
                    return null
                }
                return measurer(componentInstance.getRootRef())
            }).compact().forEach(({node, changes}) => {
                this._setStyle(node, changes)
            })
    }

    registerComponent(id, ref) {
        if (ref) {
            this._registeredComponents[id] = ref
        } else {
            delete this._registeredComponents[id]
        }
    }

    startedRendering(id) {
        this._bufferedSet.start(id)
    }

    finishedRendering(id) {
        this._bufferedSet.finish(id)
    }

    _setStyle(node, css) {
        const cssWithUnits = _.mapValues(css, value => typeof value === 'string' ? value : `${value}px`)
        Object.assign(node.style, cssWithUnits)
    }
}

module.exports = Layout
