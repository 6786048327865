'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const StaticPropsResolver = require('../components/PropsResolver')
const StyleNode = require('../components/StyleNode')
const constants = require('../utils/constants')
const {observer} = require('mobx-react')

const {PROPERTY_TYPES} = constants.pointers.components

const ObservablePropsResolver = observer(StaticPropsResolver)
const ObservableStyleNode = observer(StyleNode)

const RootComponent = (props, {componentsModelAspect, isStaticModel: staticRender}) => {
    const {id} = props
    const compType = componentsModelAspect.getStructureProperty(id, PROPERTY_TYPES.TYPE)
    const PropsResolver = staticRender ? StaticPropsResolver : ObservablePropsResolver
    const CompStyle = staticRender ? StyleNode : ObservableStyleNode
    return (
        <React.Fragment>
            <PropsResolver id={id} key="comp" {...props}>
                {compType === 'Component' ? _.noop : () => _.map(componentsModelAspect.getStructureProperty(id, PROPERTY_TYPES.COMPONENTS), childId => <RootComponent id={childId} key={childId}/>)}
            </PropsResolver>
            <CompStyle id={id} key="style"/>
        </React.Fragment>
    )
}

RootComponent.propTypes = {
    id: PropTypes.string.isRequired
}

RootComponent.contextTypes = {
    componentsModelAspect: PropTypes.shape({
        getStructureProperty: PropTypes.func.isRequired,
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired,
    isStaticModel: PropTypes.bool
}


module.exports = RootComponent
