'use strict'

const LayoutEnforcer = require('./LayoutEnforcer')

class LayoutAspect {
    constructor(componentsModelAspect) {
        this.meshLayouts = componentsModelAspect.model.meshLayout
        this.fixedChildrenIDs = componentsModelAspect.model.fixedChildrenIDs
        this._enforcer = new LayoutEnforcer()
    }

    getFetcher({id}) {
        const fetchers = {
            'Layout.isMeshLayoutMechanism': () => true,
            'Component.meshParams': (state, props) => this.meshLayouts.get(props.id),
            'Component.fixedChildrenIDs': (state, props) => this.fixedChildrenIDs.get(props.id)
        }
        return fetchers[id]
    }

    getEnforcer() {
        return this._enforcer
    }
}

module.exports = LayoutAspect
