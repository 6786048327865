'use strict'

const constants = require('../../utils/constants')
const _ = require('lodash')

const {DATA_QUERY, PROPERTY_QUERY, DESIGN_QUERY, LAYOUT, SKIN, STYLE_ID} = constants.pointers.components.PROPERTY_TYPES

module.exports = {
    create: ({displayedDAL, runtimeDAL, pointers, getDataByQuery, getStructure, getStructureProperty}) => {
        const getData = (data, id, queryName, mapName, defaultValue = null) => {
            if (data) {
                return data
            }

            const query = getStructureProperty(id, queryName)
            return query ? getDataByQuery(query, mapName) : defaultValue
        }

        const fetchers = {
            Theme: {
                colors: () => _.get(displayedDAL.get(pointers.data.getThemeDataPointer()), 'color'),
                THEME_DATA: () => displayedDAL.get(pointers.data.getThemeDataPointer())
            },
            Fonts: {
                fontsMap: () => _.get(displayedDAL.get(pointers.data.getThemeDataPointer()), 'font')
            },
            Component: {
                rotationInDegrees: (state, {id}) => getStructureProperty(id, LAYOUT).rotationInDegrees,
                renderFixedPosition: (state, {id}) => _.get(getStructureProperty(id, LAYOUT), 'fixedPosition', false),
                isCollapsed: (state, props) => _.get(fetchers.Component.compProp(state, props), 'isCollapsed', false),
                compData: (state, {compData, id}) => getData(compData, id, DATA_QUERY, 'DATA'),
                compDesign: (state, {compDesign, id}) => getData(compDesign, id, DESIGN_QUERY, 'DESIGN'),
                compProp: (state, {compProp, id}) => getData(compProp, id, PROPERTY_QUERY, 'PROPERTIES', {}),
                id: (state, {id}) => id,
                skin: (state, {id}) => {
                    const styleId = getStructureProperty(id, STYLE_ID)
                    if (styleId) {
                        const styleData = displayedDAL.get(pointers.data.getStylePointer(styleId)) || {}
                        return styleData.skin || getStructureProperty(id, SKIN)
                    }
                    return getStructureProperty(id, SKIN)
                },
                structure: (state, {id}) => getStructure(id),
                styleId: (state, {id}) => getStructureProperty(id, STYLE_ID),
                style: (state, {id}) => {
                    const {width, height/*, x: left, y: top*/} = getStructureProperty(id, LAYOUT)
                    return {
                        width,
                        height
                    }
                },
                getStyleData: (state, {id}) => (styleId = '') => {
                    const styleData = displayedDAL.get(pointers.data.getStylePointer(styleId)) || {}

                    return styleData.skin ? styleData : _.defaults(styleData, {skin: getStructureProperty(id, SKIN)})
                },
                theme: (state, {compData, id}) => getData(compData, id, STYLE_ID, 'STYLE')
            },
            DAL: {
                setCompData: (state, {id}) => newData => runtimeDAL.setCompData(id, newData)
            }
        }

        return fetchers
    }
}
