'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const RootComponent = require('../components/RootComponent')
const ComponentsModelAspect = require('../aspects/ComponentsModelAspect/ComponentsModelAspect')
const BehaviorsAspect = require('../aspects/BehaviorsAspect/BehaviorsAspect')
const LayoutAspect = require('../aspects/LayoutAspect/LayoutAspect')
const defaultPropsFetcher = require('../utils/defaultPropsFetcher')
const privatesMap = new WeakMap()

function fetchSantaTypeImpl(santaTypeDefinition, state, props) {
    const {aspects} = privatesMap.get(this)

    const santaTypeFetcher = this.props.getSantaFetcher(santaTypeDefinition) || // Host fetcher
        _(aspects).invokeMap('getFetcher', santaTypeDefinition).find(Boolean) || // Aspect fetcher
        defaultPropsFetcher.getFetcher(santaTypeDefinition) // Default value

    return santaTypeFetcher(state, props)
}

class Renderer extends React.Component {
    constructor(props) {
        super(props)
        const componentsModelAspect =
            props.aspects.componentsModelAspect || new ComponentsModelAspect(props.componentsModel, props.eventsManager, {}, props.isStaticModel)

        if (!!componentsModelAspect.isStaticModel() !== !!props.isStaticModel) {
            throw new Error('componentModelAspect isStaticModel property must be the same as the Renderer.')
        }
        const behaviorsAspect = new BehaviorsAspect(componentsModelAspect, {
            getHandlers: this.getBehaviorsHandlers.bind(this),
            eventsManager: props.eventsManager
        })
        const layoutAspect = new LayoutAspect(componentsModelAspect)

        privatesMap.set(this, {
            layout: layoutAspect.getEnforcer(),
            componentsModelAspect,
            behaviorsAspect,
            aspects: _.defaults({componentsModelAspect, behaviorsAspect, layoutAspect}, props.aspects),
            fetchSantaType: fetchSantaTypeImpl.bind(this)
        })
    }

    getBehaviorsHandlers() {
        return this.props.behaviorsHandlers
    }

    render() {
        return <React.Fragment>
            {_.map(this.props.rootCompsIds, childId => <RootComponent id={childId} key={childId} />)}
        </React.Fragment>
    }

    maybeRelayout() {
        const {layout, componentsModelAspect} = privatesMap.get(this)
        if (layout.shouldPerform()) {
            const getMeasurerForType = type => this.props.getCompClass(type).getPatchesForRenderedElement
            layout.perform({componentsModelAspect, getMeasurerForType})
        }
    }

    getChildContext() {
        const {componentsModelAspect, fetchSantaType, aspects, layout} = privatesMap.get(this)
        return {
            rootId: this.props.id,
            componentsModelAspect,
            fetchSantaType,
            getAspect: name => aspects[name],
            getCompClass: this.props.getCompClass,
            onRenderStart: id => {
                layout.startedRendering(id)
                this.props.onRenderStart({aspects}, id)
            },
            onRenderEnd: id => {
                layout.finishedRendering(id)
                this.maybeRelayout()
                this.props.onRenderEnd({aspects}, id)
            },
            onRenderError: () => {},
            registerComponent: (id, ref) => {
                layout.registerComponent(id, ref)
            }
        }
    }
}

Renderer.defaultProps = {
    getSantaFetcher: _.noop,
    aspects: {},
    behaviorsHandlers: {},
    noWrapper: false,
    onRenderStart: () => {},
    onRenderEnd: () => {},
    getMeasurerForType: () => {}
}

Renderer.displayName = 'Renderer'

Renderer.propTypes = {
    behaviorsHandlers: PropTypes.object,
    noWrapper: PropTypes.bool,
    eventsManager: PropTypes.shape({
        on: PropTypes.func.isRequired,
        off: PropTypes.func.isRequired,
        emit: PropTypes.func.isRequired
    }).isRequired,
    componentsModel: PropTypes.shape({
        structure: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired
    }),
    isStaticModel: PropTypes.bool,
    getCompClass: PropTypes.func.isRequired,
    getSantaFetcher: PropTypes.func,
    aspects: PropTypes.object,
    rootCompsIds: PropTypes.arrayOf(PropTypes.string.isRequired),
    id: PropTypes.string,
    onRenderStart: PropTypes.func,
    onRenderEnd: PropTypes.func,
    getMeasurerForType: PropTypes.func
}

Renderer.childContextTypes = {
    getCompClass: PropTypes.func.isRequired,
    fetchSantaType: PropTypes.func.isRequired,
    getAspect: PropTypes.func.isRequired,
    onRenderStart: PropTypes.func.isRequired,
    onRenderEnd: PropTypes.func.isRequired,
    onRenderError: PropTypes.func.isRequired,
    registerComponent: PropTypes.func.isRequired,
    componentsModelAspect: PropTypes.shape({
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired,
    rootId: PropTypes.string
}

module.exports = Renderer
