'use strict'

const pointers = {
    components: {
        PROPERTY_TYPES: {
            COMPONENTS: 'components',
            COMPONENT_TYPE: 'componentType',
            CONNECTION_QUERY: 'connectionQuery',
            DATA_QUERY: 'dataQuery',
            PROPERTY_QUERY: 'propertyQuery',
            DESIGN_QUERY: 'designQuery',
            BEHAVIOR_QUERY: 'behaviorQuery',
            ID: 'id',
            LAYOUT: 'layout',
            META_DATA: 'metaData',
            MOBILE_HINTS_QUERY: 'mobileHintsQuery',
            MODES: 'modes',
            SKIN: 'skin',
            STYLE_ID: 'styleId',
            PARENT: 'parent',
            TYPE: 'type'
        }
    },
    data: {
        DATA_MAPS: {
            BEHAVIORS: 'behaviors_data',
            CONNECTIONS: 'connections_data',
            DATA: 'document_data',
            DESIGN: 'design_data',
            MOBILE_HINTS: 'mobile_hints',
            PROPERTIES: 'component_properties',
            STYLE: 'theme_data'
        }
    }
}

pointers.QUERY_TO_MAP_NAME = {
    [pointers.components.PROPERTY_TYPES.BEHAVIOR_QUERY]: pointers.data.DATA_MAPS.BEHAVIORS,
    [pointers.components.PROPERTY_TYPES.CONNECTION_QUERY]: pointers.data.DATA_MAPS.CONNECTIONS,
    [pointers.components.PROPERTY_TYPES.DATA_QUERY]: pointers.data.DATA_MAPS.DATA,
    [pointers.components.PROPERTY_TYPES.DESIGN_QUERY]: pointers.data.DATA_MAPS.DESIGN,
    [pointers.components.PROPERTY_TYPES.PROPERTY_QUERY]: pointers.data.DATA_MAPS.PROPERTIES,
    [pointers.components.PROPERTY_TYPES.STYLE_ID]: pointers.data.DATA_MAPS.STYLE
}

const EVENTS = {
    COMP_DATA_RUNTIME_CHANGE: 'runtimeCompChange',
    REGISTER_COMPONENT: 'registerComponentEvent'
}

module.exports = {
    EVENTS,
    pointers
}

