'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {createCompProps} = require('../utils/propsFetcher')

class PropsResolver extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.registerComponent = ref => {
            this.context.registerComponent(this.props.id, ref)
        }
        this.state = {error: null}
    }

    componentDidCatch(error) {
        this.context.onRenderError(this.props.id, error)
        this.setState({error})
    }

    componentDidMount() {
        if (!this.isReactClass) {
            this.registerComponent(this)
        }
        this.context.onRenderEnd(this.props.id)
    }

    componentDidUpdate() {
        this.context.onRenderEnd(this.props.id)
    }

    componentWillUnmount() {
        if (!this.isReactClass) {
            this.registerComponent(null)
        }
    }

    render() {
        this.context.onRenderStart(this.props.id)
        const componentType = this.context.componentsModelAspect.getStructureProperty(this.props.id, 'componentType')
        const CompClass = this.context.getCompClass(componentType)

        if (!CompClass || this.state.error) {
            return <div id={this.props.id} data-dead-comp />
        }

        const nextProps = createCompProps(
            {fetchSantaType: this.context.fetchSantaType},
            CompClass.propTypes,
            this.props
        )

        this.isReactClass = this.isReactClass || _.hasIn(CompClass, 'prototype.isReactComponent')
        return this.isReactClass ?
            <CompClass ref={this.registerComponent} {...nextProps}>{this.props.children()}</CompClass> :
            <CompClass {...nextProps}>{this.props.children()}</CompClass>
    }
}

PropsResolver.displayName = 'PropsResolver'

PropsResolver.defaultProps = {
    children: _.noop
}

PropsResolver.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.func
}

PropsResolver.contextTypes = {
    getCompClass: PropTypes.func.isRequired,
    fetchSantaType: PropTypes.func.isRequired,
    getAspect: PropTypes.func.isRequired,
    onRenderStart: PropTypes.func.isRequired,
    onRenderEnd: PropTypes.func.isRequired,
    onRenderError: PropTypes.func.isRequired,
    registerComponent: PropTypes.func.isRequired,
    componentsModelAspect: PropTypes.shape({
        pointers: PropTypes.object.isRequired,
        displayedDAL: PropTypes.object.isRequired
    }).isRequired
}

module.exports = PropsResolver
